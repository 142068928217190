import React from 'react'
import Layout from '../components/Layout'

const NotFound = () => {
    return (
        <Layout>
            <div>
                <h2>404</h2>
                <p>Sorry, that page does not exist!</p>
            </div>
        </Layout>
    )
}

export default NotFound
